<template>
  <div>
    <div>
      <div></div>
      <canvas id="the-canvas"></canvas>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        currentPage: 0, // pdf文件页码
        pageCount: 0, // pdf文件总页数
        fileType: "pdf", // 文件类型
        src: "http://storage.xuetangx.com/public_assets/xuetangx/PDF/PlayerAPI_v1.0.6.pdf", // pdf文件地址
      };
    },
    created() {
      this._loadFile(
        "http://storage.xuetangx.com/public_assets/xuetangx/PDF/PlayerAPI_v1.0.6.pdf"
      );
    },
    methods: {
      _loadFile(url) {
        let PDFJs = require("pdfjs-dist/legacy/build/pdf.js");
        PDFJs.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry");
        console.log(PDFJs);
        const loadingTask = PDFJs.getDocument(url);
        loadingTask.promise.then((pdf) => {
          this.pdfDoc = pdf; // 保存加载的pdf文件流
          this.pages = this.pdfDoc.numPages; // 获取pdf文件的总页数
          this.$nextTick(() => {
            this._renderPage(1); // 将pdf文件内容渲染到canvas，
          });
        });
      },
      _renderPage(num) {
        this.pdfDoc.getPage(num).then((page) => {
          // let canvas = document.getElementById("the-canvas" + num);
          let canvas = document.getElementById("the-canvas");
          // 获取原始大小的数据
          var vp = page.getViewport({ scale: 1 });
          console.log(vp, "222");
          let ctx = canvas.getContext("2d");
          var scale = (960 / vp.width).toFixed(2);
          let viewport = page.getViewport({
            scale: scale,
          });
          console.log(viewport);
          canvas.width = viewport.width;
          canvas.height = viewport.height;
          canvas.style.width = 100 + "%";
          canvas.style.height = 100 + "vh";
          canvas.style.border = "1px solid #000";
          let renderContext = {
            canvasContext: ctx,
            viewport: viewport,
          };
          page.render(renderContext);
          // if (this.pages > num) {
          //   this._renderPage(num + 1);
          // }
        });
      },
      // pdf() {
      //   let pdf = require("pdfjs-dist/legacy/build/pdf.js");
      //   let pdfone = require("pdfjs-dist/build/pdf.worker.entry");
      // },
      // // 改变PDF页码,val传过来区分上一页下一页的值,0上一页,1下一页
      changePdfPage(val) {
        // console.log(val)
        if (val === 0 && this.currentPage > 1) {
          this.currentPage--;
          // console.log(this.currentPage)
        }
        if (val === 1 && this.currentPage < this.pageCount) {
          this.currentPage++;
          // console.log(this.currentPage)
        }
      },
      // pdf加载时
      loadPdfHandler(e) {
        this.currentPage = 1; // 加载的时候先加载第一页
      },
    },
  };
</script>
<style lang="scss" scoped></style>
